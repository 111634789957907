<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card>
      <v-card-title class="pb-8">
        <v-icon left>mdi-account</v-icon>
        Dados de acesso
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.firstName"
              dense
              label="Nome *"
              outlined
              persistent-placeholder
              :disabled="!isEditMode"
              :rules="[rules.nomeRules]"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="form.lastName"
              dense
              label="Sobrenome *"
              outlined
              persistent-placeholder
              :disabled="!isEditMode"
              :rules="[rules.sobrenomeRules]"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              id="ipt-agmail"
              v-model="form.emailAddress"
              dense
              label="Email *"
              outlined
              persistent-placeholder
              :disabled="!isEditMode"
              :error-messages="mailMessage"
              :rules="[rules.emailRules]"
              @blur="existsEmail()"
            />
          </v-col>
          <v-col sm="6">
            <v-text-field
              id="phoneField"
              v-model="form.cellPhone"
              v-mask="'(##) 9####-####'"
              dense
              label="Celular *"
              outlined
              persistent-placeholder
              :rules="[rules.cellRules]"
              placeholder="(00) 00000-0000"
            />
          </v-col>
          <v-col sm="6">
            <v-text-field
              id="userField"
              v-model="form.username"
              :rules="[rules.usernameRules, rules.username5Rules]"
              :error-messages="userMessage"
              label="Usuário *"
              dense
              outlined
              persistent-placeholder
              placeholder="Min. 5 caracteres"
              @blur="existsUsername()"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text :disabled="!isEditMode" class="btn-ag-tertiary" @click="close">Cancelar</v-btn>
        <v-btn :disabled="!valid" class="btn-ag-primary" @click="editMode">
          {{ isEditMode ? 'Salvar' : 'Editar' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import UserService from '@/services/userService.js';
import cloneDeep from 'lodash/cloneDeep';
import { mapState } from 'vuex';

import { eventBus } from '../../../../main.js';

export default {
  props: ['id'],

  data() {
    return {
      form: {},
      isInit: false,
      isEditMode: false,
      formEdit: {},
      valid: false,
      mailMessage: '',
      userMessage: '',
      rules: {
        nomeRules: (v) => (!!v && /^[A-Za-z]+$/.test(v)) || 'Nome deve ser informado',
        sobrenomeRules: (v) => (!!v && /^[A-Za-z]+$/.test(v)) || 'Sobrenome deve ser informado',
        // usuario5Rules: (value) => /^(?=.*[a-zA-Z]).{5,}$/.test(value) || 'Deve ter 5 caracteres',
        emailRules: (v) =>
          /^[a-zA-Z].*(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v,
          ) || 'E-mail válido deve ser informado.',

        cellRules: (v) =>
          (!!v && /^\([1-9]{2}\) (?:[2-8]|9[1-9])[0-9]{3}-[0-9]{4}$/.test(v)) ||
          'Número de celular válido deve ser informado',

        usernameRules: (value) => !!value || 'Preencha um login válido',
        username5Rules: (value) => /^(?=.*[a-zA-Z]).{5,}$/.test(value) || 'Deve ter 5 caracteres',
      },
    };
  },

  computed: {
    ...mapState('cadastrosGerenciais/usuario', {
      user: (state) => state.user,
      loading: (state) => state.loading,
    }),
  },

  watch: {
    loading: {
      handler() {
        this.init();
      },

      deep: true,
      immediate: true,
    },
  },

  methods: {
    showToast(message, type, topToast) {
      let toast = {
        show: true,
        type: type,
        message: message,
        topToast: topToast,
      };
      eventBus.$emit('openToast', toast);
    },

    init() {
      if (this.isInit || !this.user.id) return;
      this.form = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        emailAddress: this.user.emailAddress,
      };
      this.isInit = true;
    },

    existsEmail() {
      if (this.form.emailAddress != this.formEdit.emailAddress) {
        UserService.existsEmail(this.form.emailAddress)
          .then((response) => {
            if (response.data === false) {
              this.mailMessage = '';
            } else {
              this.mailMessage = 'E-mail já cadastrado';
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.mailMessage = '';
      }
    },

    existsUsername() {
      if (this.form.username != this.formEdit.username) {
        UserService.existsUsername(this.form.username)
          .then((response) => {
            if (response.data == false) {
              this.userMessage = '';
            } else {
              this.userMessage = 'Usuário já cadastrado';
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.userMessage = '';
      }
    },

    editMode() {
      if (this.isEditMode === true) {
        this.save();
      }
      this.isEditMode = !this.isEditMode;
      this.formEdit = cloneDeep(this.form);
    },

    close() {
      this.isEditMode = !this.isEditMode;
      this.mailMessage = '';
      this.form = this.formEdit;
    },

    async save() {
      this.form.id = this.user.id;
      this.form.authorities = this.user.authorities;
      this.user.firstName = this.form.firstName;
      this.user.lastName = this.form.lastName;
      this.user.emailAddress = this.form.emailAddress;

      let emailAvailable = await this.checkEmailAvailability();
      if (!emailAvailable) {
        this.showToast('Este endereço de e-mail não está disponível!', 'error', 78);
        return;
      }
      await UserService.updateOtherUser(this.user);
      this.showToast('Alterações realizadas com sucesso!', 'success', 78);
    },

    async checkEmailAvailability() {
      let currentUser = await UserService.getUser();

      let current = currentUser.data.emailAddress;
      let edited = document.querySelector('#ipt-agmail').value;

      if (current != edited) {
        try {
          let r = await UserService.existsEmail(this.form.emailAddress);
          return !r.data;
        } catch (e) {
          console.log(e);
        }
      } else {
        return true;
      }
    },
  },
};
</script>
